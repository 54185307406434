import * as React from 'react';

const EllipseDark = ({ ellipseParams }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={ellipseParams?.width || 890}
		height={ellipseParams?.height || 890}
		viewBox={ellipseParams?.viewBox || null}
		style={{
			position: ellipseParams?.position || '',
			opacity: ellipseParams?.opacity || 1,
			mixBlendMode: ellipseParams?.mixBlendMode || '',
		}}
	>
		<defs>
			<linearGradient id="a" x1={0.159} y1={0.053} x2={0.698} y2={0.828} gradientUnits="objectBoundingBox">
				<stop offset={0} stopColor="#0f49b1" />
				<stop offset={1} stopColor="#0f49b1" stopOpacity={0} />
			</linearGradient>
		</defs>
		<circle data-name="Ellipse 5" cx={445} cy={445} r={445} opacity={0.287} fill="url(#a)" />
	</svg>
);

export default EllipseDark;
